import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <>
      <div className="container center">
        <h1>網頁未找到</h1>
        <Link to="/">到首頁</Link>
      </div>
    </>

  )
}

export default NotFound;