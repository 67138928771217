import React, { useState, useEffect } from "react";

import failIcon from "./icon_fail.png";
import successIcon from "./icon_success.png";
import queryString from "query-string";

const url = "https://api.wificontrolbox.com/v1";

const VerifyEmail = () => {
  const [httpStatus, setHttpStatus] = useState(500);
  const [loading, setLoading] = useState(true);
  const parsed = queryString.parse(window.location.search);
  console.log(parsed);
  const token = parsed.token;

  useEffect(() => {
    fetch(`${url}/users/verify-email?token=${token}`)
      .then((response) => {
        console.log(response);
        setHttpStatus(response.status);
      })
      .catch((error) => {
        console.error(error);
        setHttpStatus(401);
      })
      .finally(() => setLoading(false)); // Set loading to false regardless of fetch outcome
  }, [token]);

  if (loading) {
    return (
      <div className="App-header">
        <p>Loading...</p>
      </div>
    );
  } else if (httpStatus === 204) {
    return (
      <div className="App-header">
        <img src={successIcon} className="Leader-icon" alt="Success" />
        <h1>您的帳號已經確認！</h1>
        <p>
          感謝您使用AIRMON iLIVING，
          <br />
          嶄新的智慧生活即將展開！
        </p>
        <></>
        <p>
          您現在可以開啟AIRMON iLIVING並重新登入，
          <br />
          並繼續註冊流程。
        </p>
        <></>
        <p>如有問題請聯絡技術團隊</p>
        <a href="tel:0800300766">客服專線</a>
        <></>
        <p>
          再一次感謝您使用AIRMON iLIVING，
          <br />
          嶄新的智慧生活即將展開！
        </p>
      </div>
    );
  } else {
    return (
      <div className="App-header">
        <img src={failIcon} className="Leader-icon" alt="Fail" />
        <h1>好像有些問題！</h1>
        <p>
          因為連結已失效，
          <br />
          您的帳號無法完成驗證
        </p>
        <></>
        <p>您可以開啟 AIRMON iLIVING 重新註冊。</p>
        如有問題請聯絡技術團隊
        <a href="http://www.google.com/">客服專線</a>
        <></>
        <p>
          再一次感謝您使用AIRMON iLIVING，
          <br />
          嶄新的智慧生活即將展開！
        </p>
      </div>
    );
  }
};

export default VerifyEmail;
