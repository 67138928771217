import logo from "../logo.png"

const Topper = () => {
    return (
        <header className="Topper">
            <img src={logo} alt="logo" className="logo"></img>
        </header>
    )
}

export default Topper