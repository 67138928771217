
const Home = () => {
    return(
    <div className="container center">
        <h1>
            UpYoung
        </h1>
    </div>

    )
}

export default Home;