import React, { useState } from 'react'
import queryString from "query-string";

import Form from "../components/Form";
import successIcon from "./icon_success.png"

const ResetPassword = () => {
    const [formDone, setFormDone] = useState(false);

    const parsed = queryString.parse(window.location.search);
    // console.log(parsed);
    const token = parsed.token;
    if (formDone) {
        return (
            <div className="App-header">
                <img src={successIcon} className="Leader-icon" alt="Success" />
                <h1>您的密碼已經變更！</h1>
                <p>感謝您使用AIRMON</p>
                <></>
                <p>您現在可以使用新的密碼登入AIRMON iLIVING</p>
                <></>
                <p>如有問題請聯絡技術團隊</p>
                <a href="tel:0800300766">客服專線</a>
                <></>
                <p>再一次感謝您使用AIRMON iLIVING，<br />嶄新的智慧生活即將展開！</p>
            </div>
        )
    } else {
        return (
            <div className="App-header">
                <h2>請重新設定您的密碼</h2>
                <Form token={token} setFormDone={setFormDone} />
                <></>
                <p>如有問題請聯絡技術團隊</p>
                <a href="tel:0800300766">客服專線</a>
                <></>
                <p>再一次感謝您使用AIRMON iLIVING，<br />嶄新的智慧生活即將展開！</p>

            </div>
        )
    }
}

export default ResetPassword;