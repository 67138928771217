import './App.css';
import Topper from "./components/Topper";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import VerifyEmail from './pages/VerifyEmail.js';
import ResetPassword from './pages/ResetPassword.js';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import Footer from './components/Footer.js';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Topper />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  )
}
export default App;
