import logo from "../upyoung_logo_white.png"

const Footer = () => {
    return (
        <footer className="Footer">
            <img src={logo} alt="logo" className="logo"></img>
        </footer>
    )
}

export default Footer