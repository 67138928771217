import React, { useState } from 'react'

import PasswordInput from './PasswordInput';

const url = 'https://api.wificontrolbox.com/v1';

const Form = ({ token, setFormDone }) => {
    const [apiresponse, setApiResponse] = useState({
        loading: false,
        success: false,
        status: '',
        error: false
    });
    const [formInput, setFormInput] = useState({
        password: "",
        confirmPassword: "",
    });
    const [formError, setFormError] = useState({
        password: "",
        confirmPassword: "",
        response : "",
    });

    const handleUserInput = (name, value) => {
        setFormInput({
            ...formInput,
            [name]: value,
        })
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        let inputError = {
            password: "",
            confirmPassword: "",
            response : "",
        }

        let inputRespone = {
            loading: false,
            success: false,
            status: '',
            error: false
        }
        setApiResponse({
            ...inputRespone
        });
        if (!formInput.password) {
            setFormError({
                ...inputError,
                password: "密碼不可為空",
            })
            return;
        }
        if (!/^(?=.*[0-9])(?=.*[a-zA-Z])(?!.*(.)\1{4}).{9,}$/.test(formInput.password)) {
            setFormError({
                ...inputError,
                password: "請輸入9個字元以上的密碼（需同時包含英數字）",
            })
            return;
        }
        if (!/^(?!.*(12345|23456|34567|45678|56789).*).{9,}$/.test(formInput.password)) {
            setFormError({
                ...inputError,
                password: "不可有數字或英文字母連續 5 碼相同或連續",
            })
            return;
        }
        
        if (formInput.confirmPassword !== formInput.password) {
            setFormError({
                ...inputError,
                confirmPassword: "密碼不相符",
            })
            return;
        }
        setFormError(inputError);
        
        setApiResponse({
            ...apiresponse,
            loading: true
        });
        await fetch(`${url}/users/reset-password`, {
            body: JSON.stringify({
                token: token,
                newPassword: formInput.password
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(function (response) {
                console.log(response);
                setApiResponse({
                    loading: false,
                    success: response.ok,
                    status: response.status,
                    error: response.status !== 200 ? true : false
                });
                if (response.ok) {
                    console.log(response.ok);
                    setFormDone(true);
                }else{
                    if (response.status === 401) {
                        setFormError({
                            ...inputError,
                             response : "網址缺失金鑰"
                         })
                    }
                    switch (response.status) {
                        case 401:
                            setFormError({
                                ...inputError,
                                 response : "網址缺失金鑰"
                             })
                            break;
                        case 404:
                            setFormError({
                                ...inputError,
                                 response : "金鑰過期，請重新申請"
                             })
                            break;
                    
                        default:
                            setFormError({
                                ...inputError,
                                 response : "密碼重製失敗: "+ response.status
                             })
                            break;
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
                setApiResponse({
                    error: true,
                    loading: false,
                    status: error
                });
            });
        
    }

    return (
        <form onSubmit={handleSubmit} className='form'>
            <p className="label">新密碼</p>
            <PasswordInput name="password"
                type="password"
                onChange={({ target }) => handleUserInput(target.name, target.value)}
                value={formInput.password}
                placeholder='請輸入9個字元以上的密碼（需同時包含英數字）'
            />
            <p className="error-message">{formError.password}</p>

            <p className="label">確認新密碼</p>
            <PasswordInput name="confirmPassword"
                onChange={({ target }) => handleUserInput(target.name, target.value)}
                value={formInput.confirmPassword}
                placeholder='請輸入9個字元以上的密碼（需同時包含英數字）'
            />
            <p className="error-message">{formError.confirmPassword}</p>
            {apiresponse.success && <p className="success-message">密碼重製成功</p>}
            <p className="error-message">{formError.response}</p>
            {/* {apiresponse.error && <p className="error-message">錯誤{apiresponse.status}</p>} */}
            <input className='submit' type="submit" value="確認儲存" disabled={apiresponse.loading || apiresponse.success} />
        </form>
    )
}

export default Form;