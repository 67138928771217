import React, { useState } from "react";
import { Icon } from "react-icons-kit";
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'

const PasswordInput = ({ name, value, onChange, placeholder }) => {
  const [show, setshow] = useState(false);
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (show === false) {
      setIcon(eye);
      setshow(true);
    } else {
      setIcon(eyeOff);
      setshow(false);
    }
  };

  return (
    <div className= "password-wrapper">
      <input className="password"
        name={name}
        type={show ? "text" : "password"}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
      <span className="toggle-password" onClick={handleToggle}>
        <Icon  icon={icon} size={20} />
      </span>
    </div>
  );
};

export default PasswordInput;
